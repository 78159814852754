@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter/Inter-Variable.ttf');
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input::placeholder {
  font-size: 14px;
}

input {
  accent-color: #009933;
}

.sem-margin-nas-divs-filhas {
  div {
    margin: 0 !important;
  }
}

.Toastify__toast-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: fit-content !important;
  max-width: 506px;
  min-width: 320px;
}

.Toastify__toast {
  width: fit-content !important;
  max-width: 100vw;
}